import domready from "domready";
import ScrollOut from "scroll-out";

// import main styles in dev mode only
if (process.env.NODE_ENV === "development") {
  import("../scss/styles.scss");
}

class App {
  constructor() {
    this.init();
  }

  init() {
    this.scrollout = ScrollOut({
      once: true,
      // offset: 50
    });
  }

}

domready(() => {
  setTimeout(() => new App(), process.env.NODE_ENV === "development" ? 500 : 0);
});
